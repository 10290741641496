import React, { useContext } from "react";
import { getCookie } from "tiny-cookie";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";

const Alertbar = () => {
  let dismissedAlerts = [];
  if (typeof document === "object") {
    dismissedAlerts = JSON.parse(getCookie("dismissedAlerts")) || [];
  }
  const { alertBarData, setAlertBarData } = useContext(ResourcesContext);
  // const onClickDismiss = () => {
  //   const expirationDate = new Date();
  //   expirationDate.setHours(expirationDate.getHours() + 24);
  //   setCookie(
  //     "dismissedAlerts",
  //     JSON.stringify([...dismissedAlerts, alertBarData.entityId]),
  //     { expires: expirationDate }
  //   );
  //   setAlertBarData(null);
  // };

  return (
    <div className="container mb-6">
      <div className="alert-bar">
        <div className="is-size-7-touch is-size-6">
          {/* {!!alertBarData.title && (
            <h2 className="has-text-white has-text-weight-semibold is-size-7-touch is-size-6">
              {alertBarData.title}
            </h2>
          )} */}
          <div className="columns is-centered">
            <div className="column is-three-quarters has-text-centered">
            {!!alertBarData.text && (
              <div
                className="alert-bar__text dangerously-html-handle"
                dangerouslySetInnerHTML={{ __html: alertBarData.text }}
              />
            )}
            </div>
          </div>
          {/* <button
            className="alert-bar__dismiss"
            onClick={() => onClickDismiss()}
          >
            <i
              className="fa fa-times has-text-white"
            />
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Alertbar;
