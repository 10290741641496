import React, {
  useRef,
  useEffect,
  useContext,
  useMemo
} from "react";

//Components
import { MCHero } from "./hero/MCHero";

import Alertbar from "src/components/Layout/Alertbar";
//Hooks & Helpers
import useHashValue from "src/components/helpers/useHashValue";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import useMcHomePage from "src/hooks/useMcHomepage";
import getContentConnectorFields from "src/components/helpers/getContentConnectorFields";
import getSchemaMarkup from "../helpers/getSchemaMarkup";
import getBannerData from "src/components/helpers/getBannerData";
import { AccruentImg,SEO,Banner,LocalizedLink ,ContentConnector} from "../commonComponents";
import { getCommonHeroImages, getPageDataJsonPath } from "src/utils";
const getImageObject = require("src/../helpers/getImageObject");
const getMetaData = require("src/../helpers/getMetaData");

const getSolutionData = (data) => {
 const ProductLogo = getImageObject(data?.relationships?.fieldSolutionItems?.[0]?.relationships?.fieldProductLogo,data?.relationships?.fieldSolutionItems?.[0]?.fieldProductLogo)
 const Graphics = getImageObject(data?.relationships?.fieldGraphic,data?.fieldGraphic)
 let parsedSolution = {
    name: data?.fieldOverrideItem ? data?.fieldTitle : data?.relationships?.fieldSolutionItems?.[0]?.fieldProductName,
    description: data?.fieldOverrideItem ? data?.fieldBody :data?.relationships?.fieldSolutionItems?.[0]?.fieldProductDescription,
    image: data?.fieldOverrideItem ? Graphics :ProductLogo,
    path: data?.fieldOverrideItem ? data?.fieldCtaUrl?.url :data?.relationships?.fieldSolutionItems?.[0]?.entityUrl?.path,
    linkText: data?.fieldOverrideItem ? data?.fieldCtaUrl?.title : ""
  }
  return parsedSolution;
}

const McIndexPage = ({ data, location, path }) => {
  const { hash } = useHashValue(location);
  const scrollRef = useRef();
  const { alertBarData } = useContext(ResourcesContext);
  const { mcData }= useMcHomePage();

  let parsedMcData = mcData
  useEffect(() => {
    if (hash && scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, [hash]);

  const solutionData = parsedMcData?.relationships?.fieldSolutionSection
  const OGMarkup = getOGMarkup('MC','homepage');

  let contentConnectors = [];
  if (Array.isArray(parsedMcData?.relationships?.fieldContentSections)) {
    contentConnectors = getContentConnectorFields(
      parsedMcData?.relationships?.fieldContentSections,
      "ems homepage",
      'en'
    );
  }

  const schemaMarkup = getSchemaMarkup(parsedMcData?.fieldSchemaMarkup)
  const bannerData = parsedMcData?.relationships?.fieldBannerSection ? getBannerData(parsedMcData?.relationships?.fieldBannerSection) : "";
  const entityMetatags = getMetaData(parsedMcData?.metatag)
  if (!entityMetatags?.find((meta) => (meta?.name === 'description'))) {
    const desc = parsedMcData?.relationships?.fieldHeroSection?.[0]?.fieldHeroDescription
    entityMetatags.push({ name: 'description', content: desc })
  }
  const pageDataPath = getPageDataJsonPath('/index')
  
  const heroSection = parsedMcData?.relationships?.fieldHeroSection
  const rightBackgroundImage = heroSection?.[0]?.relationships?.fieldRightBackgroundImage?.relationships?.thumbnail?.url;
  const backgroundImage = heroSection?.[0]?.relationships?.fieldBackgroundImage?.relationships?.thumbnail?.url;
  const preloadImages = [{src:rightBackgroundImage}, {src:backgroundImage}]
  return (
    <div className="mc-homepage">
      <SEO
        alternatives={[
          {
            href: "",
            hreflang: "en"
          },
          {
            href: "",
            hreflang: "x-default"
          }
        ]}
        OGMarkup={OGMarkup}
        schemaMarkup={schemaMarkup}
        meta={entityMetatags}
        preloads= {[pageDataPath]}  
        preloadedImages={preloadImages}
      />
      <MCHero heroMcData={heroSection}/>
      {bannerData?.fieldShowReportBanner && (
          <div className="tw-container_custom mc-banner tw-mt-0 lg:!-tw-mt-10">
            <div className="tw-flex columns tw-columns">
              <Banner
                {...bannerData}
              />
            </div>
          </div>
        )}
      <div className="homepage-content-wrapper tw-pb-8 tw-pt-[60px] lg:!tw-pb-[76px] md:tw-ml-4 md:tw-mr-4 lg:tw-ml-0 lg:tw-mr-0">
        {!!alertBarData && <Alertbar />}
        {solutionData && solutionData.length > 0 && (
          <section className="choose-your-product">
            <div className="tw-container_custom">
              <div className="tw-flex columns tw-columns">
                <div className="tw-column md:tw-w-9/12">
                <h2 className="title title-3">
                  {parsedMcData?.fieldSubtitle}
                </h2>
                <p className="!tw-text-accruent_black subtitle-2 !tw-text-xl">
                  {parsedMcData?.fieldBody}
                </p>
                </div>
              </div>
              <div className="columns tw-flex tw-columns is-mobile tw-justify-center is-multiline tw-justify-stretch industry-cards tw-text-accruent_gray1 ">
                {solutionData.map((solution) => {
                  const parsedSolution = getSolutionData(solution);
                  const image = parsedSolution?.image?.gatsbyImage?parsedSolution?.image:null
                  return (
                    <div
                      className="tw-column tw-w-full md:tw-w-1/2 lg:tw-w-1/3 tw-mt-2"
                      key={solution.uuid}
                    >
                      <LocalizedLink to={parsedSolution?.path} className="tw-font-bold tw-text-link-color">
                        <div className="card homepage-product-card tw-justify-start !tw-rounded-t-[48px] !tw-rounded-br-[48px] tw-flex tw-flex-col !tw-h-full !tw-border-0 tw-shadow-[0_2px_12px_0_rgba(125,125,125,0.16)]">
                          <div className="card-image card-main-image">
                            <figure className="image">
                               {image?.gatsbyImage && <AccruentImg file={image} className={image? "resource-card-image" : 'card-local-image'} loading="eager" width={738} style={{width: '100%'}}/>}
                            </figure>
                          </div>
                          <div className="card-content tw-text-accruent_sapphire !tw-py-7 !tw-px-3">
                            <h6 className="title-6 tw-pt-2.5 !tw-mb-4">
                              {parsedSolution?.name}
                            </h6>
                            <div className="card-text tw-min-h-[60px] tw-text-base tw-text-accruent_gray1 tw-leading-[21px] tw-font-normal tw-mb-10 tw-font-Roboto">
                            <p className="tw-text-accruent_black">
                              <div dangerouslySetInnerHTML={{
                              __html: parsedSolution?.description
                              }}/>
                            </p>
                            </div>
                            <div className="align-btn tw-absolute tw-bottom-[26px]">
                              {parsedSolution?.linkText ? parsedSolution?.linkText :"Learn More  ›"}
                            </div>
                          </div>
                        </div>
                      </LocalizedLink>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        )}
        {contentConnectors.map((data, index) => (
          <div key={index} className="tw-container_custom !tw-mt-14">
            <ContentConnector
              key={index}
              data={data}
              pageType={parsedMcData?.type?.id}
              locale={'en'}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default McIndexPage;
