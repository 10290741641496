import { useState, useEffect } from "react";

const useHashValue = (location, initialValue) => {
  const [hash, setHash] = useState("");
  useEffect(() => {
    setHash(location.hash.replace("#", ""));
  }, [location]);

  return { hash, setHash };
};

export default useHashValue;
