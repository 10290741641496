import React from "react";
import { schemaMarkupRendering } from "src/components/helpers/schemaMarkupRendering";
import {  LocalizedLink, LCPImage } from "src/components/commonComponents";

export const MCHero = ({ heroMcData }) => {
  const mcHeroData = heroMcData?.[0];
  const backgroundImage = mcHeroData?.relationships?.fieldBackgroundImage?.relationships?.thumbnail?.url;
  const rightBackgroundImage = mcHeroData?.relationships?.fieldRightBackgroundImage?.relationships?.thumbnail?.url;
  return (
    <section className="hero is-block  mc-home-hero" style={{ backgroundImage: `url(${rightBackgroundImage})` }}>
      <LCPImage/>
        {schemaMarkupRendering(mcHeroData)}
      <div className="banner-left-graphic" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="container section">
          <div className="columns">
            <div className="column is-9 is-full-tablet hero-left-container">
              <h1>{mcHeroData?.title}</h1>
              <div className="subtitle" >{mcHeroData?.fieldPreHeading}</div>
              <p
                className="mt-4 content"
              >
                {mcHeroData?.fieldHeroDescription}

              </p>

              <div className="is-flex ems-hero-buttons">
                {mcHeroData?.fieldHeroCtaUrl?.title && <LocalizedLink
                  to={mcHeroData?.fieldHeroCtaUrl?.url}
                  className="button is-medium is-primary has-text-white "
                >
                  {mcHeroData?.fieldHeroCtaUrl?.title}
                </LocalizedLink>}
                {mcHeroData?.fieldHeroSecondaryCta?.title && <LocalizedLink
                  to={mcHeroData?.fieldHeroSecondaryCta?.url}
                  className="button is-medium is-outlined is-accruent-sapphire has-text-accruent-sapphire "
                >
                  {mcHeroData?.fieldHeroSecondaryCta?.title}
                </LocalizedLink>}

              </div>
            </div>
            <div className="column is-3 is-full-tablet product-page-container about-hero-image-container">
            </div>
          </div>

        </div>
      </div>
    </section>
  )

};

