import { useStaticQuery, graphql } from "gatsby";

export default function useMcHomePage(locale = 'en') {
  const data = useStaticQuery(graphql`
  {
    allNodeHomepage(
      sort: {fields: created, order: DESC}
      filter: {langcode: {eq: "en"}, field_target_site: {drupal_internal__target_id: {eq: 453}}}
      limit: 1
    ) {
      nodes {
        nid: drupal_internal__nid
        created
        type: node_type {
          id: drupal_internal__target_id
        }
        fieldTitle: field_title
        fieldSchemaMarkup: field_schema_markup
        fieldSubtitle: field_subtitle
        fieldBody: field_body
        metatag {
          attributes {
            content
            href
            name
            rel
          }
          tag
        }
        relationships {
          fieldHeroSection: field_hero_section {
            title
            fieldPreHeading: field_pre_heading
            fieldHeroDescription: field_hero_description
            fieldHeroCtaUrl: field_hero_cta_url {
              title
              url
            }
            fieldHeroSecondaryCta: field_hero_secondary_cta {
              title
              url
            }
            fieldImage: field_image {
              alt
            }
            relationships {
              fieldImage: field_image {
                gatsbyImage(width: 10, layout: FULL_WIDTH, placeholder:BLURRED)
              }
              fieldBackgroundImage: field_background_image {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    gatsbyImage(width: 10, layout: FULL_WIDTH, placeholder:BLURRED)
                  }
                  thumbnail {
                    url
                  }
                }
              }
              fieldRightBackgroundImage: field_right_background_image {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    gatsbyImage(width: 10, layout: FULL_WIDTH, placeholder:BLURRED)
                  }
                  thumbnail {
                    url
                  }
                }
              }
            }
            fieldVideoSchemaMarkup: field_video_schema_markup
          }
          fieldBannerSection: field_banner_section {
            fieldShowReportBanner: field_show_report_banner
            body {
              value: processed
            }
            fieldCtaUrl: field_cta_url {
              title
              url
            }
            title
            relationships {
              fieldMediaImage: field_media_image {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    gatsbyImage(layout: FULL_WIDTH, width: 376, placeholder:BLURRED)
                  }
                }
              }
            }
          }
          fieldSolutionSection: field_solution_section {
            id
            title
            nid: drupal_internal__nid
            fieldOverrideItem: field_override_item
            relationships {
              fieldSolutionItems: field_solution_items {
                ... on taxonomy_term__services {
                  id
                  tid: drupal_internal__tid
                  uuid: drupal_id
                  vid {
                    id: drupal_internal__target_id
                  }
                  name
                  entityUrl: path {
                    path: alias
                  }
                  fieldProductName: field_product_name
                  fieldProductDescription: field_product_description_
                  fieldProductLogo: field_product_logo {
                    alt
                  }
                  relationships {
                    fieldProductLogo: field_product_logo {
                      gatsbyImage(width: 10, layout: FULL_WIDTH, placeholder:BLURRED)
                    }
                  }
                }
                ... on taxonomy_term__solutions {
                  id
                  tid: drupal_internal__tid
                  uuid: drupal_id
                  vid {
                    id: drupal_internal__target_id
                  }
                  name
                  entityUrl: path {
                    path: alias
                  }
                  fieldProductName: field_product_name
                  fieldProductDescription_: field_product_description_
                  fieldProductLogo: field_product_logo {
                    alt
                  }
                  relationships {
                    fieldProductLogo: field_product_logo {
                      gatsbyImage(width: 10, layout: FULL_WIDTH, placeholder:BLURRED)
                    }
                  }
                }
              }
            }
          }
          fieldContentSections: field_content_sections {
            fieldMakeAnchor: field_make_anchor
            title
            relationships {
              fieldProxy: field_proxy {
                ... on node__roi_calculator {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  uuid: drupal_id
                  nid: drupal_internal__nid
                  title
                  fieldHideTitle: field_hide_title
                  fieldRoiCalculatorId: field_roi_calculator_id
                  fieldEmbedCode: field_embed_code
                }
                ... on node__benefits_section {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  nid: drupal_internal__nid
                  uuid: drupal_id
                  title
                  fieldHideTitle: field_hide_title
                  fieldSubtitle: field_subtitle
                  fieldBenefitsSectionStyling: field_benefits_section_styling
                  body {
                    value: processed
                  }
                  fieldCtaUrl: field_cta_url {
                    title
                    url
                  }
                  fieldLayout: field_layout
                  fieldBiStyle: field_bi_style
                  relationships {
                    fieldBenefitItem: field_benefit_item {
                      nid: drupal_internal__nid
                      uuid: drupal_id
                      title
                      fieldHideTitle: field_hide_title
                      fieldPreHeading: field_pre_heading
                      body {
                        value: processed
                      }
                      fieldIcon: field_icon {
                        iconName: icon_name
                        style
                      }
                      fieldLinkToOriginalArticle: field_link_to_original_article {
                        url
                        title
                      }
                      fieldKeyFeaturesBullets: field_key_features_bullets
                      fieldCtaUrl: field_cta_url {
                        url
                        title
                      }
                      fieldSecondaryCta: field_secondary_cta {
                        url
                        title
                      }
                      fieldGraphic: field_graphic {
                        alt
                      }
                      status
                      relationships {
                        fieldGraphic: field_graphic {
                          gatsbyImage(layout: FULL_WIDTH, width: 10, placeholder:BLURRED)
                        }
                      }
                    }
                  }
                }
                ... on node__content_block_section {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  nid: drupal_internal__nid
                  uuid: drupal_id
                  hideBlockSectionTitle: field_hide_title
                  blockSectionTitle: title
                  blockSectionTitleLink: field_url
                  blockSectionDesc: body {
                    value: processed
                  }
                  blockSectionType: field_section_type
                  blockSectionFormat: field_section_format
                  fieldPreHeading: field_pre_heading
                  fieldShowBackgroundPattern: field_show_background_pattern
                  fieldIcon: field_icon {
                    iconName: icon_name
                    style
                  }
                  fieldSectionCta: field_section_cta {
                    url
                    title
                  }
                  relationships {
                    fieldContentBlock: field_content_block {
                      nid: drupal_internal__nid
                      uuid: drupal_id
                      created
                      fieldFormat: field_format
                      body {
                        value: processed
                      }
                      title
                      fieldImage: field_image {
                        alt
                      }
                      fieldCtaUrl: field_cta_url {
                        title
                        url
                      }
                      fieldPreHeading: field_pre_heading
                      fieldSubtitle: field_subtitle
                      fieldSecondaryCta: field_secondary_cta {
                        title
                        url
                      }
                      status
                      relationships {
                        fieldMediaImage: field_media_image {
                          fieldImageCaption: field_image_caption
                          fieldImage: field_image {
                            alt
                          }
                          relationships {
                            fieldImage: field_image {
                              gatsbyImage(layout: FULL_WIDTH, aspectRatio: 1.77, width: 10, placeholder:BLURRED)
                            }
                          }
                        }
                        fieldImage: field_image {
                          gatsbyImage(layout: FULL_WIDTH, aspectRatio: 1.77, width: 10, placeholder:BLURRED)
                        }
                        fieldVideoRef: field_video_ref {
                          field_media_video_embed_field
                          field_title
                          field_video_transcript {
                            value: processed
                          }
                          thumbnail {
                            alt
                          }
                          relationships {
                            thumbnail {
                              gatsbyImage(layout: FULL_WIDTH, aspectRatio: 1.77, width: 10, placeholder:BLURRED)
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on node__resource_carousel_teaser {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  title
                  fieldHideTitle: field_hide_title
                  body {
                    value
                  }
                  relationships {
                    fieldBrandLogosSection: field_brand_logos_section {
                      nid: drupal_internal__nid
                      uuid: drupal_id
                      title
                      fieldHideTitle: field_hide_title
                      fieldShowCtaButton: field_show_cta_button
                      fieldCtaUrl: field_cta_url {
                        title
                        url
                      }
                      fieldLogoStyle: field_logo_style
                      fieldShowBackgroundPattern: field_show_background_pattern
                      fieldLogoImages: field_logo_images {
                        alt
                      }
                      fieldBody: field_body
                      relationships {
                        fieldLogoImages: field_logo_images {
                          gatsbyImage(layout: FULL_WIDTH, width: 10, placeholder:BLURRED)
                        }
                      }
                    }
                    fieldResourceCarouselItem: field_resource_carousel_item {
                      title
                      fieldOverrideItem: field_override_item
                      fieldCustomerName: field_customer_name
                      fieldCustomerFeedback: field_customer_feedback
                      fieldCustomerDesignation: field_customer_designation
                      fieldTestimonialType: field_testimonial_type
                      fieldBrief: field_brief
                      fieldCtaUrl: field_cta_url {
                        title
                        url: uri
                      }
                      fieldTeaserLogo: field_teaser_logo {
                        alt
                      }
                      fieldTeaserImage: field_teaser_image {
                        alt
                      }
                      status
                      relationships {
                        fieldTeaserImage: field_teaser_image {
                          gatsbyImage(fit: COVER, width: 600, quality:70, layout: CONSTRAINED, placeholder:BLURRED)
                        }
                        fieldTeaserLogo: field_teaser_logo {
                          gatsbyImage(fit: COVER, width: 200, quality:70, layout: CONSTRAINED, placeholder:BLURRED)
                        }
                        fieldResourceCarouselItems: field_resource_carousel_items {
                          ... on node__case_study {
                            type: node_type {
                              id: drupal_internal__target_id
                            }
                            title
                            status
                            entityUrl: path {
                              path: alias
                            }
                            fieldTargetSite: field_target_site {
                              drupal_internal__target_id
                            }
                            fieldCustomerName: field_customer_name
                            fieldHeroVideoLink: field_hero_video_link
                            fieldCustomerDesignation: field_customer_designation
                            fieldCustomerFeedback: field_customer_feedback
                            fieldReferenceVisibility: field_reference_visibility
                            fieldTestimonialType: field_testimonial_type
                            fieldBrief: field_brief
                            changed
                            fieldTeaserLogo: field_teaser_logo {
                              alt
                            }
                            fieldTeaserImage: field_teaser_image {
                              alt
                            }
                            relationships {
                              fieldMediaImage: field_media_image {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(fit: COVER, width: 600, quality:70, layout: CONSTRAINED, placeholder:BLURRED)
                                  }
                                }
                              }
                              fieldTeaserImage: field_teaser_image {
                                gatsbyImage(fit: COVER, width: 600, quality:70, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                              fieldTeaserLogo: field_teaser_logo {
                                gatsbyImage(fit: COVER, width: 200, quality:70, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                            }
                          }
                          ... on node__press_release {
                            type: node_type {
                              id: drupal_internal__target_id
                            }
                            title
                            status
                            entityUrl: path {
                              path: alias
                            }
                            fieldTargetSite: field_target_site {
                              drupal_internal__target_id
                            }
                            fieldCustomerName: field_customer_name
                            fieldCustomerDesignation: field_customer_designation
                            fieldCustomerFeedback: field_customer_feedback
                            fieldReferenceVisibility: field_reference_visibility
                            fieldTestimonialType: field_testimonial_type
                            changed
                            fieldTeaserLogo: field_teaser_logo {
                              alt
                            }
                            fieldTeaserImage: field_teaser_image {
                              alt
                            }
                            relationships {
                              fieldMediaImage: field_media_image {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(fit: COVER, width: 600, quality:70, layout: CONSTRAINED, placeholder:BLURRED)
                                  }
                                }
                              }
                              fieldTeaserImage: field_teaser_image {
                                gatsbyImage(fit: COVER, width: 600, quality:70, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                              fieldTeaserLogo: field_teaser_logo {
                                gatsbyImage(fit: COVER, width: 200, quality:70, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                            }
                          }
                          ... on node__video {
                            type: node_type {
                              id: drupal_internal__target_id
                            }
                            title
                            status
                            entityUrl: path {
                              path: alias
                            }
                            fieldTargetSite: field_target_site {
                              drupal_internal__target_id
                            }
                            fieldCustomerName: field_customer_name
                            fieldCustomerDesignation: field_customer_designation
                            fieldCustomerFeedback: field_customer_feedback
                            fieldReferenceVisibility: field_reference_visibility
                            fieldTestimonialType: field_testimonial_type
                            changed
                            fieldTeaserLogo: field_teaser_logo {
                              alt
                            }
                            fieldTeaserImage: field_teaser_image {
                              alt
                            }
                            relationships {
                              fieldHeroMedia: field_hero_media {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(fit: COVER, width: 600, quality:70, layout: CONSTRAINED, placeholder:BLURRED)
                                    url
                                  }
                                }
                              }
                              fieldMediaImage: field_media_image {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(fit: COVER, width: 600, quality:70, layout: CONSTRAINED, placeholder:BLURRED)
                                  }
                                }
                              }
                              fieldTeaserImage: field_teaser_image {
                                gatsbyImage(fit: COVER, width: 600, quality:70, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                              fieldTeaserLogo: field_teaser_logo {
                                gatsbyImage(fit: COVER, width: 200, quality:70, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on node__text_area {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  uuid: drupal_id
                  nid: drupal_internal__nid
                  fieldHideTitle: field_hide_title
                  title
                  body {
                    value: processed
                    summary: summary
                  }
                }
                ... on node__block_quote {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  nid: drupal_internal__nid
                  uuid: drupal_id
                  fieldQuoteBody: field_quote_body
                  company
                  fieldCustomerDesignation: field_customer_designation
                  title
                  fieldImage: field_image {
                    alt
                  }
                  relationships {
                    fieldImage: field_image {
                      gatsbyImage(layout: FULL_WIDTH, width: 10, placeholder:BLURRED)
                    }
                  }
                }
                ... on node__intro_content_section {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  nid: drupal_internal__nid
                  uuid: drupal_id
                  title
                  fieldPreHeading: field_pre_heading
                  fieldLogoImages: field_logo_images {
                    alt
                  }
                  relationships {
                    fieldLogoImages: field_logo_images {
                      gatsbyImage(width: 250, layout: CONSTRAINED, placeholder:BLURRED)
                    }
                    fieldImgBullets: field_img_bullets {
                      fieldBulletPoint:  field_bullet_point
                      fieldImgBulletImg: field_img_bullet_img {
                        alt
                      }
                      relationships {
                        fieldImgBulletImg: field_img_bullet_img {
                          gatsbyImage(width: 300, layout: CONSTRAINED, placeholder:BLURRED)
                        }
                      }
                    }
                    fieldIntroContentItem: field_intro_content_item {
                      nid: drupal_internal__nid
                      uuid: drupal_id
                      fieldStyle: field_style
                      title
                      body {
                        value: processed
                      }
                      fieldCtaUrl: field_cta_url {
                        url
                        options {
                          attributes {
                            rel
                            target
                          }
                        }
                        title
                      }
                      fieldGraphic: field_graphic {
                        alt
                      }
                      relationships {
                        fieldGraphic: field_graphic {
                          gatsbyImage(width: 600, layout: CONSTRAINED, placeholder:BLURRED)
                        }
                      }
                    }
                  }
                }
                ... on node__industry_features {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  uuid: drupal_id
                  nid: drupal_internal__nid
                  title
                  body {
                    value: processed
                  }
                  relationships {
                    fieldMediaImage: field_media_image {
                      fieldImage: field_image {
                        alt
                      }
                      relationships {
                        fieldImage: field_image {
                          gatsbyImage(width: 10, layout: FULL_WIDTH, placeholder:BLURRED)
                        }
                      }
                    }
                    fieldFeatureItem: field_feature_item  {
                      title
                      fieldHideTitle: field_hide_title
                      fieldFeatureItemBody: field_feature_item_body
                      uuid: drupal_id
                      nid: drupal_internal__nid
                    }
                  }
                }
                ... on node__full_width_img {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  uuid: drupal_id
                  nid: drupal_internal__nid
                  title
                  relationships {
                    fieldMediaImage: field_media_image {
                      fieldImage: field_image {
                        alt
                      }
                      relationships {
                        fieldImage: field_image {
                          gatsbyImage(width: 10, layout: FULL_WIDTH, placeholder:BLURRED)
                        }
                      }
                    }
                  }
                }
                ... on node__cta {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  uuid: drupal_id
                  nid: drupal_internal__nid
                  fieldCtaUrl: field_cta_url {
                    title
                    url
                  }
                  fieldHideTitle: field_hide_title
                  title
                  fieldSubtitle: field_subtitle
                  fieldSecondaryCta: field_secondary_cta {
                    title
                    url
                  }
                  fieldGenericCtaBody: field_generic_cta_body {
                    value: processed
                  }
                }
                ... on node__video_section {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  uuid: drupal_id
                  nid: drupal_internal__nid
                  body {
                    value: processed
                  }
                  title
                  fieldHideTitle: field_hide_title
                  fieldCtaUrl: field_cta_url {
                    title
                    url
                  }
                  relationships {
                    fieldVideoRef: field_video_ref {
                      fieldMediaVideoEmbedField: field_media_video_embed_field
                    }
                  }
                }
                ... on node__statistics {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  uuid: drupal_id
                  nid: drupal_internal__nid
                  title
                  fieldPreHeading: field_pre_heading
                  fieldShowBackgroundPattern: field_show_background_pattern
                  fieldStatisticSectionType: field_statistic_section_type
                  body {
                    value: processed
                  }
                  fieldCtaUrl: field_cta_url {
                    title
                    url
                  }
                  relationships {
                    fieldStatistic: field_statistic {
                      uuid: drupal_id
                      nid: drupal_internal__nid
                      title
                      fieldHideTitle: field_hide_title
                      fieldPrefix: field_prefix
                      fieldShowDollarSign: field_show_dollar_sign
                      fieldShowPlusSign: field_show_plus_sign
                      fieldSta: field_sta
                      fieldStatisticSuffix: field_statistic_suffix
                      fieldStatisticsRange: field_statistics_range
                      fieldStatisticsDefinition: field_statistics_definition {
                        value: processed
                      }
                      status
                    }
                  }
                }
                ... on node__qa_list {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  nid: drupal_internal__nid
                  uuid: drupal_id
                  title
                  fieldQaSchema: field_qa_schema
                  relationships {
                    field_qa {
                      uuid: drupal_id
                      nid: drupal_internal__nid
                      title
                      fieldAnswer: field_answer
                      fieldFormattedAnswer: field_formatted_answer {
                        value: processed
                      }
                      status
                    }
                  }
                }
                ... on node__case_studies_section {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  uuid: drupal_id
                  nid: drupal_internal__nid
                  caseStudiesSectionTitle: title
                  hideCaseStudiesSectionTitle: field_hide_title
                  caseStudiesSectionDesc: body {
                    value: processed
                  }
                  relationships {
                    fieldCaseStudyItem: field_case_study_item {
                      uuid: drupal_id
                      nid: drupal_internal__nid
                      type: node_type {
                        id: drupal_internal__target_id
                      }
                      promote
                      entityUrl: path {
                        path: alias
                      }
                      title
                      status
                      fieldCustomerName: field_customer_name
                      fieldCustomerDesignation: field_customer_designation
                      fieldCustomerFeedback: field_customer_feedback
                      fieldReferenceVisibility: field_reference_visibility
                      fieldTestimonialType: field_testimonial_type
                      fieldBrief: field_brief
                      changed
                      fieldTeaserImage: field_teaser_image {
                        alt
                      }
                      fieldTeaserLogo: field_teaser_logo {
                        alt
                      }
                      relationships {
                        fieldMediaImage: field_media_image {
                          fieldImage: field_image {
                            alt
                          }
                          relationships {
                            fieldImage: field_image {
                              gatsbyImage(width: 650, layout: CONSTRAINED, quality:70, placeholder:BLURRED)
                            }
                          }
                        }
                        fieldTeaserImage: field_teaser_image {
                          gatsbyImage(width: 650, layout: CONSTRAINED, quality:70, placeholder:BLURRED)
                        }
                        fieldTeaserLogo: field_teaser_logo {
                          gatsbyImage(width: 200, layout: CONSTRAINED, quality:70, placeholder:BLURRED)
                        }
                      }
                    }
                  }
                }
                ... on node__related_section {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  title
                  fieldHideTitle: field_hide_title
                  fieldPreHeading: field_pre_heading
                  fieldBody: field_body
                  fieldRelatedFormat: field_related_format
                  status
                  nid: drupal_internal__nid
                  uuid: drupal_id
                  fieldCtaUrl: field_cta_url {
                    url
                    title
                  }
                  relationships {
                    fieldRelatedItem: field_related_item {
                      ... on node__press_release {
                        uuid: drupal_id
                        nid: drupal_internal__nid
                        type: node_type {
                          id: drupal_internal__target_id
                        }
                        title
                        body {
                          value: processed
                        }
                        entityUrl: path {
                          path: alias
                        }
                        fieldResourceDescription: field_resource_description
                        fieldTargetSite: field_target_site {
                          targetId: drupal_internal__target_id
                        }
                        locale: langcode
                        status
                        fieldReferenceVisibility: field_reference_visibility
                        relationships {
                          industry {
                            fieldRemoveFromFilter: field_remove_from_filter
                            fieldFilterLabel: field_filter_label
                            fieldPageTitle: field_page_title
                            id: drupal_internal__tid
                            name
                          }
                          fieldMediaImage: field_media_image {
                            fieldImage: field_image {
                              alt
                            }
                            relationships {
                              fieldImage: field_image {
                                gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                            }
                          }
                        }
                      }
                      ... on article {
                        uuid: drupal_id
                        nid: drupal_internal__nid
                        type: node_type {
                          id: drupal_internal__target_id
                        }
                        title
                        body {
                          value: processed
                        }
                        entityUrl: path {
                          path: alias
                        }
                        fieldResourceDescription: field_resource_description
                        fieldTargetSite: field_target_site {
                          targetId: drupal_internal__target_id
                        }
                        locale: langcode
                        status
                        fieldReferenceVisibility: field_reference_visibility
                        relationships {
                          industry {
                            fieldRemoveFromFilter: field_remove_from_filter
                            fieldFilterLabel: field_filter_label
                            fieldPageTitle: field_page_title
                            id: drupal_internal__tid
                            name
                          }
                          fieldMediaImage: field_media_image {
                            fieldImage: field_image {
                              alt
                            }
                            relationships {
                              fieldImage: field_image {
                                gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                            }
                          }
                        }
                      }
                      ... on node__blog_entry {
                        uuid: drupal_id
                        nid: drupal_internal__nid
                        type: node_type {
                          id: drupal_internal__target_id
                        }
                        title
                        body {
                          value: processed
                        }
                        entityUrl: path {
                          path: alias
                        }
                        fieldResourceDescription: field_resource_description
                        fieldTargetSite: field_target_site {
                          targetId: drupal_internal__target_id
                        }
                        locale: langcode
                        status
                        fieldReferenceVisibility: field_reference_visibility
                        relationships {
                          industry {
                            fieldRemoveFromFilter: field_remove_from_filter
                            fieldFilterLabel: field_filter_label
                            fieldPageTitle: field_page_title
                            id: drupal_internal__tid
                            name
                          }
                          fieldMediaImage: field_media_image {
                            fieldImage: field_image {
                              alt
                            }
                            relationships {
                              fieldImage: field_image {
                                gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                            }
                          }
                        }
                      }
                      ... on node__brochure {
                        uuid: drupal_id
                        nid: drupal_internal__nid
                        type: node_type {
                          id: drupal_internal__target_id
                        }
                        title
                        body {
                          value: processed
                        }
                        entityUrl: path {
                          path: alias
                        }
                        fieldResourceDescription: field_resource_description
                        fieldTargetSite: field_target_site {
                          targetId: drupal_internal__target_id
                        }
                        locale: langcode
                        status
                        fieldReferenceVisibility: field_reference_visibility
                        relationships {
                          industry {
                            fieldRemoveFromFilter: field_remove_from_filter
                            fieldFilterLabel: field_filter_label
                            fieldPageTitle: field_page_title
                            id: drupal_internal__tid
                            name
                          }
                          fieldMediaImage: field_media_image {
                            fieldImage: field_image {
                              alt
                            }
                            relationships {
                              fieldImage: field_image {
                                gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                            }
                          }
                        }
                      }
                      ... on node__case_study {
                        uuid: drupal_id
                        nid: drupal_internal__nid
                        type: node_type {
                          id: drupal_internal__target_id
                        }
                        title
                        body {
                          value: processed
                        }
                        entityUrl: path {
                          path: alias
                        }
                        fieldResourceDescription: field_resource_description
                        fieldTargetSite: field_target_site {
                          targetId: drupal_internal__target_id
                        }
                        locale: langcode
                        status
                        fieldReferenceVisibility: field_reference_visibility
                        relationships {
                          industry {
                            fieldRemoveFromFilter: field_remove_from_filter
                            fieldFilterLabel: field_filter_label
                            fieldPageTitle: field_page_title
                            id: drupal_internal__tid
                            name
                          }
                          fieldMediaImage: field_media_image {
                            fieldImage: field_image {
                              alt
                            }
                            relationships {
                              fieldImage: field_image {
                                gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                            }
                          }
                        }
                      }
                      ... on node__ebook {
                        uuid: drupal_id
                        nid: drupal_internal__nid
                        type: node_type {
                          id: drupal_internal__target_id
                        }
                        title
                        body {
                          value: processed
                        }
                        entityUrl: path {
                          path: alias
                        }
                        fieldResourceDescription: field_resource_description
                        fieldTargetSite: field_target_site {
                          targetId: drupal_internal__target_id
                        }
                        locale: langcode
                        status
                        fieldReferenceVisibility: field_reference_visibility
                        relationships {
                          industry {
                            fieldRemoveFromFilter: field_remove_from_filter
                            fieldFilterLabel: field_filter_label
                            fieldPageTitle: field_page_title
                            id: drupal_internal__tid
                            name
                          }
                          fieldMediaImage: field_media_image {
                            fieldImage: field_image {
                              alt
                            }
                            relationships {
                              fieldImage: field_image {
                                gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                            }
                          }
                        }
                      }
                      ... on node__webinar {
                        uuid: drupal_id
                        nid: drupal_internal__nid
                        type: node_type {
                          id: drupal_internal__target_id
                        }
                        title
                        body {
                          value: processed
                        }
                        entityUrl: path {
                          path: alias
                        }
                        fieldResourceDescription: field_resource_description
                        fieldTargetSite: field_target_site {
                          targetId: drupal_internal__target_id
                        }
                        locale: langcode
                        status
                        fieldReferenceVisibility: field_reference_visibility
                        relationships {
                          industry {
                            fieldRemoveFromFilter: field_remove_from_filter
                            fieldFilterLabel: field_filter_label
                            fieldPageTitle: field_page_title
                            id: drupal_internal__tid
                            name
                          }
                          fieldMediaImage: field_media_image {
                            fieldImage: field_image {
                              alt
                            }
                            relationships {
                              fieldImage: field_image {
                                gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                            }
                          }
                        }
                      }
                      ... on node__white_paper {
                        uuid: drupal_id
                        nid: drupal_internal__nid
                        type: node_type {
                          id: drupal_internal__target_id
                        }
                        title
                        body {
                          value: processed
                        }
                        entityUrl: path {
                          path: alias
                        }
                        fieldResourceDescription: field_resource_description
                        fieldTargetSite: field_target_site {
                          targetId: drupal_internal__target_id
                        }
                        locale: langcode
                        status
                        fieldReferenceVisibility: field_reference_visibility
                        relationships {
                          industry {
                            fieldRemoveFromFilter: field_remove_from_filter
                            fieldFilterLabel: field_filter_label
                            fieldPageTitle: field_page_title
                            id: drupal_internal__tid
                            name
                          }
                          fieldMediaImage: field_media_image {
                            fieldImage: field_image {
                              alt
                            }
                            relationships {
                              fieldImage: field_image {
                                gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                            }
                          }
                        }
                      }
                      ... on node__infographic {
                        uuid: drupal_id
                        nid: drupal_internal__nid
                        type: node_type {
                          id: drupal_internal__target_id
                        }
                        title
                        body {
                          value: processed
                        }
                        entityUrl: path {
                          path: alias
                        }
                        fieldResourceDescription: field_resource_description
                        fieldTargetSite: field_target_site {
                          targetId: drupal_internal__target_id
                        }
                        locale: langcode
                        status
                        fieldReferenceVisibility: field_reference_visibility
                        relationships {
                          industry {
                            fieldRemoveFromFilter: field_remove_from_filter
                            fieldFilterLabel: field_filter_label
                            fieldPageTitle: field_page_title
                            id: drupal_internal__tid
                            name
                          }
                          fieldMediaImage: field_media_image {
                            fieldImage: field_image {
                              alt
                            }
                            relationships {
                              fieldImage: field_image {
                                gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                            }
                          }
                        }
                      }
                      ... on node__knowledge_hub_ {
                        uuid: drupal_id
                        nid: drupal_internal__nid
                        type: node_type {
                          id: drupal_internal__target_id
                        }
                        title
                        body {
                          value: processed
                        }
                        entityUrl: path {
                          path: alias
                        }
                        fieldResourceDescription: field_resource_description
                        fieldTargetSite: field_target_site {
                          targetId: drupal_internal__target_id
                        }
                        locale: langcode
                        status
                        fieldReferenceVisibility: field_reference_visibility
                        relationships {
                          industry {
                            fieldRemoveFromFilter: field_remove_from_filter
                            fieldFilterLabel: field_filter_label
                            fieldPageTitle: field_page_title
                            id: drupal_internal__tid
                            name
                          }
                          fieldMediaImage: field_media_image {
                            fieldImage: field_image {
                              alt
                            }
                            relationships {
                              fieldImage: field_image {
                                gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                            }
                          }
                        }
                      }
                      ... on node__podcast_episode {
                        uuid: drupal_id
                        nid: drupal_internal__nid
                        type: node_type {
                          id: drupal_internal__target_id
                        }
                        title
                        body {
                          value: processed
                        }
                        entityUrl: path {
                          path: alias
                        }
                        fieldResourceDescription: field_resource_description
                        fieldTargetSite: field_target_site {
                          targetId: drupal_internal__target_id
                        }
                        locale: langcode
                        status
                        fieldReferenceVisibility: field_reference_visibility
                        relationships {
                          industry {
                            fieldRemoveFromFilter: field_remove_from_filter
                            fieldFilterLabel: field_filter_label
                            fieldPageTitle: field_page_title
                            id: drupal_internal__tid
                            name
                          }
                          fieldMediaImage: field_media_image {
                            fieldImage: field_image {
                              alt
                            }
                            relationships {
                              fieldImage: field_image {
                                gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                            }
                          }
                        }
                      }
                      ... on node__video {
                        uuid: drupal_id
                        nid: drupal_internal__nid
                        type: node_type {
                          id: drupal_internal__target_id
                        }
                        title
                        body {
                          value: processed
                        }
                        entityUrl: path {
                          path: alias
                        }
                        fieldResourceDescription: field_resource_description
                        fieldTargetSite: field_target_site {
                          targetId: drupal_internal__target_id
                        }
                        locale: langcode
                        status
                        fieldReferenceVisibility: field_reference_visibility
                        relationships {
                          industry {
                            fieldRemoveFromFilter: field_remove_from_filter
                            fieldFilterLabel: field_filter_label
                            fieldPageTitle: field_page_title
                            id: drupal_internal__tid
                            name
                          }
                          fieldHeroMedia: field_hero_media {
                            fieldImage: field_image {
                              alt
                            }
                            relationships {
                              fieldImage: field_image {
                                gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                                url
                              }
                            }
                          }
                          fieldMediaImage: field_media_image {
                            fieldImage: field_image {
                              alt
                            }
                            relationships {
                              fieldImage: field_image {
                                gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                              }
                            }
                          }
                        }
                      }
                    }
                    fieldResourceReference: field_resource_reference {
                      status
                      fieldOverrideItem: field_override_item
                      fieldCtaUrl: field_cta_url {
                        title
                        url
                      }
                      fieldResourceDescription: field_resource_description
                      fieldTitle: field_title
                      fieldGraphic: field_graphic {
                        alt
                      }
                      relationships {
                        fieldGraphic: field_graphic {
                          url
                          gatsbyImage(width: 10, layout: FULL_WIDTH, placeholder:BLURRED)
                        }
                        fieldResourceReferenceItem: field_resource_reference_item {
                          ... on article {
                            uuid: drupal_id
                            nid: drupal_internal__nid
                            type: node_type {
                              id: drupal_internal__target_id
                            }
                            title
                            body {
                              value: processed
                            }
                            entityUrl: path {
                              path: alias
                            }
                            fieldResourceDescription: field_resource_description
                            fieldTargetSite: field_target_site {
                              targetId: drupal_internal__target_id
                            }
                            locale: langcode
                            status
                            fieldReferenceVisibility: field_reference_visibility
                            relationships {
                              industry {
                                fieldRemoveFromFilter: field_remove_from_filter
                                fieldFilterLabel: field_filter_label
                                fieldPageTitle: field_page_title
                                id: drupal_internal__tid
                                name
                              }
                              fieldMediaImage: field_media_image {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                                  }
                                }
                              }
                            }
                          }
                          ... on node__blog_entry {
                            uuid: drupal_id
                            nid: drupal_internal__nid
                            type: node_type {
                              id: drupal_internal__target_id
                            }
                            title
                            body {
                              value: processed
                            }
                            entityUrl: path {
                              path: alias
                            }
                            fieldResourceDescription: field_resource_description
                            fieldTargetSite: field_target_site {
                              targetId: drupal_internal__target_id
                            }
                            locale: langcode
                            status
                            fieldReferenceVisibility: field_reference_visibility
                            relationships {
                              industry {
                                fieldRemoveFromFilter: field_remove_from_filter
                                fieldFilterLabel: field_filter_label
                                fieldPageTitle: field_page_title
                                id: drupal_internal__tid
                                name
                              }
                              fieldMediaImage: field_media_image {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                                  }
                                }
                              }
                            }
                          }
                          ... on node__case_study {
                            uuid: drupal_id
                            nid: drupal_internal__nid
                            type: node_type {
                              id: drupal_internal__target_id
                            }
                            title
                            body {
                              value: processed
                            }
                            entityUrl: path {
                              path: alias
                            }
                            fieldResourceDescription: field_resource_description
                            fieldTargetSite: field_target_site {
                              targetId: drupal_internal__target_id
                            }
                            locale:langcode
                            status
                            fieldReferenceVisibility: field_reference_visibility
                            relationships {
                              industry {
                                fieldRemoveFromFilter: field_remove_from_filter
                                fieldFilterLabel: field_filter_label
                                fieldPageTitle: field_page_title
                                id: drupal_internal__tid
                                name
                              }
                              fieldMediaImage: field_media_image {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                                  }
                                }
                              }
                            }
                          }
                          ... on node__ebook {
                            uuid: drupal_id
                            nid: drupal_internal__nid
                            type: node_type {
                              id: drupal_internal__target_id
                            }
                            title
                            body {
                              value: processed
                            }
                            entityUrl: path {
                              path: alias
                            }
                            fieldResourceDescription: field_resource_description
                            fieldTargetSite: field_target_site {
                              targetId: drupal_internal__target_id
                            }
                            locale:langcode
                            status
                            fieldReferenceVisibility: field_reference_visibility
                            relationships {
                              industry {
                                fieldRemoveFromFilter: field_remove_from_filter
                                fieldFilterLabel: field_filter_label
                                fieldPageTitle: field_page_title
                                id: drupal_internal__tid
                                name
                              }
                              fieldMediaImage: field_media_image {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                                  }
                                }
                              }
                            }
                          }
                          ... on node__infographic {
                            uuid: drupal_id
                            nid: drupal_internal__nid
                            type: node_type {
                              id: drupal_internal__target_id
                            }
                            title
                            body {
                              value: processed
                            }
                            entityUrl: path {
                              path: alias
                            }
                            fieldResourceDescription: field_resource_description
                            fieldTargetSite: field_target_site {
                              targetId: drupal_internal__target_id
                            }
                            locale:langcode
                            status
                            fieldReferenceVisibility: field_reference_visibility
                            relationships {
                              industry {
                                fieldRemoveFromFilter: field_remove_from_filter
                                fieldFilterLabel: field_filter_label
                                fieldPageTitle: field_page_title
                                id: drupal_internal__tid
                                name
                              }
                              fieldMediaImage: field_media_image {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                                  }
                                }
                              }
                            }
                          }
                          ... on node__knowledge_hub_ {
                            uuid: drupal_id
                            nid: drupal_internal__nid
                            type: node_type {
                              id: drupal_internal__target_id
                            }
                            title
                            body {
                              value: processed
                            }
                            entityUrl: path {
                              path: alias
                            }
                            fieldResourceDescription: field_resource_description
                            fieldTargetSite: field_target_site {
                              targetId: drupal_internal__target_id
                            }
                            locale:langcode
                            status
                            fieldReferenceVisibility: field_reference_visibility
                            relationships {
                              industry {
                                fieldRemoveFromFilter: field_remove_from_filter
                                fieldFilterLabel: field_filter_label
                                fieldPageTitle: field_page_title
                                id: drupal_internal__tid
                                name
                              }
                              fieldMediaImage: field_media_image {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                                  }
                                }
                              }
                            }
                          }
                          ... on node__press_release {
                            uuid: drupal_id
                            nid: drupal_internal__nid
                            type: node_type {
                              id: drupal_internal__target_id
                            }
                            title
                            body {
                              value: processed
                            }
                            entityUrl: path {
                              path: alias
                            }
                            fieldResourceDescription: field_resource_description
                            fieldTargetSite: field_target_site {
                              targetId: drupal_internal__target_id
                            }
                            locale:langcode
                            status
                            fieldReferenceVisibility: field_reference_visibility
                            relationships {
                              industry {
                                fieldRemoveFromFilter: field_remove_from_filter
                                fieldFilterLabel: field_filter_label
                                fieldPageTitle: field_page_title
                                id: drupal_internal__tid
                                name
                              }
                              fieldMediaImage: field_media_image {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                                  }
                                }
                              }
                            }
                          }
                          ... on node__video {
                            uuid: drupal_id
                            nid: drupal_internal__nid
                            type: node_type {
                              id: drupal_internal__target_id
                            }
                            title
                            body {
                              value: processed
                            }
                            entityUrl: path {
                              path: alias
                            }
                            fieldResourceDescription: field_resource_description
                            fieldTargetSite: field_target_site {
                              targetId: drupal_internal__target_id
                            }
                            locale:langcode
                            status
                            fieldReferenceVisibility: field_reference_visibility
                            relationships {
                              industry {
                                fieldRemoveFromFilter: field_remove_from_filter
                                fieldFilterLabel: field_filter_label
                                fieldPageTitle: field_page_title
                                id: drupal_internal__tid
                                name
                              }
                              fieldVideoRef: field_video_ref {
                                thumbnail {
                                  alt
                                }
                                relationships {
                                  thumbnail {
                                    gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                                  }
                                }
                              }
                              fieldHeroMedia: field_hero_media {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                                    url
                                  }
                                }
                              }
                              fieldMediaImage: field_media_image {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                                  }
                                }
                              }
                            }
                          }
                          ... on node__webinar {
                            uuid: drupal_id
                            nid: drupal_internal__nid
                            type: node_type {
                              id: drupal_internal__target_id
                            }
                            title
                            body {
                              value: processed
                            }
                            entityUrl: path {
                              path: alias
                            }
                            fieldResourceDescription: field_resource_description
                            fieldTargetSite: field_target_site {
                              targetId: drupal_internal__target_id
                            }
                            locale:langcode
                            status
                            fieldReferenceVisibility: field_reference_visibility
                            relationships {
                              industry {
                                fieldRemoveFromFilter: field_remove_from_filter
                                fieldFilterLabel: field_filter_label
                                fieldPageTitle: field_page_title
                                id: drupal_internal__tid
                                name
                              }
                              fieldMediaImage: field_media_image {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                                  }
                                }
                              }
                            }
                          }
                          ... on node__white_paper {
                            uuid: drupal_id
                            nid: drupal_internal__nid
                            type: node_type {
                              id: drupal_internal__target_id
                            }
                            title
                            body {
                              value: processed
                            }
                            entityUrl: path {
                              path: alias
                            }
                            fieldResourceDescription: field_resource_description
                            fieldTargetSite: field_target_site {
                              targetId: drupal_internal__target_id
                            }
                            locale:langcode
                            status
                            fieldReferenceVisibility: field_reference_visibility
                            relationships {
                              industry {
                                fieldRemoveFromFilter: field_remove_from_filter
                                fieldFilterLabel: field_filter_label
                                fieldPageTitle: field_page_title
                                id: drupal_internal__tid
                                name
                              }
                              fieldMediaImage: field_media_image {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                                  }
                                }
                              }
                            }
                          }
                          ... on node__brochure {
                            uuid: drupal_id
                            nid: drupal_internal__nid
                            type: node_type {
                              id: drupal_internal__target_id
                            }
                            title
                            body {
                              value: processed
                            }
                            entityUrl: path {
                              path: alias
                            }
                            fieldResourceDescription: field_resource_description
                            fieldTargetSite: field_target_site {
                              targetId: drupal_internal__target_id
                            }
                            locale: langcode
                            status
                            fieldReferenceVisibility: field_reference_visibility
                            relationships {
                              industry {
                                fieldRemoveFromFilter: field_remove_from_filter
                                fieldFilterLabel: field_filter_label
                                fieldPageTitle: field_page_title
                                id: drupal_internal__tid
                                name
                              }
                              fieldMediaImage: field_media_image {
                                fieldImage: field_image {
                                  alt
                                }
                                relationships {
                                  fieldImage: field_image {
                                    gatsbyImage(width: 432, layout: CONSTRAINED, placeholder:BLURRED)
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on node__tabs_section {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  uuid: drupal_id
                  nid: drupal_internal__nid
                  title
                  fieldHideTitle: field_hide_title
                  body {
                    value: processed
                  }
                  fieldTabSectionFormat: field_tab_section_format
                  relationships {
                    fieldTabSectionItem: field_tab_section_item {
                      uuid: drupal_id
                      nid: drupal_internal__nid
                      title
                      body {
                        value: processed
                      }
                      relationships {
                        fieldTabItem: field_tab_item {
                          uuid: drupal_id
                          nid: drupal_internal__nid
                          title
                          status
                          body {
                            value: processed
                          }
                          fieldKeyFeaturesBullets: field_key_features_bullets
                          fieldCtaUrl: field_cta_url {
                            title
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `);

  const entities = data?.allNodeHomepage?.nodes?.[0]
  return { mcData: entities};
}